// custom typefaces
import '@kfonts/nanum-square-round-otf';
import '@kfonts/nanum-square';

// custom CSS styles
import './src/style.css';

import 'normalize.css';

// Highlighting for code blocks
import 'prism-themes/themes/prism-vsc-dark-plus.min.css';
import 'prismjs/plugins/line-highlight/prism-line-highlight.css';

/**
 * @param {import('gatsby').ShouldUpdateScrollArgs}
 * @returns {boolean | number[]}
 */
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  // 검색 시 쿼리 스트링 변경 될 때마다 최상단으로 스크롤 되는 것 방지
  if (
    routerProps?.location?.pathname?.includes('posts') &&
    prevRouterProps?.location?.pathname?.includes('posts')
  ) {
    return false;
  }

  const currentPosition = getSavedScrollPosition(routerProps.location);
  return currentPosition || [0, 0];
};
